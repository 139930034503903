<template >
  <div id="blog">
    <div id="headerHome" class="col-sm-12 col-md-12 color-bg-1">
      <HeaderHome :name="user.fullname" />
    </div>

    <div class="politica-cont">
      <div class="titulo-pol">TERMINOS <span>Y CONDICIONES</span></div>
      <p class="texto-pol">
        Este contrato describe los términos y condiciones generales (en adelante
        <b>“TERMINOS Y CONDICIONES”</b>) aplicables al uso de los contenidos,
        adquisición de productos y servicios ofrecidos a través del sitio web
        <b>www.cimarronesfc.mx</b> (en adelante <b>“SITIO WEB”</b>), del cual es titular
        <b>DEPORTIVO HMO, S.A. DE C.V.</b> (en adelante <b>“Club Cimarrones de Sonora FC”</b>
        ó <b>“LA EMPRESA”</b>). Cualquier persona que desee acceder, hacer uso del
        sitio y/o adquirir los productos ofrecidos en el “SITIO WEB”, podrá
        hacerlo sujetándose a los presentes TERMINOS Y CONDICIONES, así como el
        Aviso de Privacidad Integral publicado en nuestro SITIO WEB. En todo
        caso, cualquier persona que no acepte los presentes términos y
        condiciones deberá abstenerse de utilizar el SITIO WEB y/o adquirir y/o
        solicitar cualquiera de los productos y/o servicios que se ofertan. </p>

        <p class="texto-pol"><b>1.OBJETO</b></p>
        <p class="texto-pol">Los presentes TERMINOS Y CONDICIONES, tienen por objeto regular
        el acceso, utilización y adquisición de productos de la TIENDA EN LINEA
        (“TIENDA ONLINE”) en el SITIO WEB, entendiendo que este incluye
        cualquier tipo de contenido y/o productos que se encuentren a la venta
        al público en general en el dominio <b>www.cimarronesfc.mx.</b></p>
        <p class="texto-pol"><b>Club Cimarrones de Sonora FC </b>se reserva el derecho de modificar en cualquier momento y
        sin previo aviso la presentación, contenidos, funcionalidad, productos,
        servicios y/o la configuración que pudiera estar contenida en el SITIO
        WEB; en este sentido el USUARIO reconoce y acepta que <b>Club Cimarrones de
        Sonora FC</b> en cualquier momento podrá interrumpir, desactivar y/o
        cancelar cualquiera de los elementos que conforman el SITIO WEB o el
        acceso a los mismos.</p>
        <p class="texto-pol">El acceso al SITIO WEB por parte del USUARIO tiene
        carácter libre, es decir, no es necesario el previo registro para poder
        ingresar, sin embargo, bajo ciertas circunstancias como la adquisición
        de productos que se ofertan será necesario llenar un formulario para
        efecto de contar con la información necesaria para dar trámite a la
        compra del USUARIO.</p>
        <p class="texto-pol">El SITIO WEB está dirigido principalmente a usuarios
        residentes de la república Mexicana y en estricto respeto a las leyes
        que rigen el orden mexicano, por lo cual <b>Club Cimarrones de Sonora FC</b> no
        asegura que el SITIO WEB cumpla total o parcialmente con la legislación
        de otros países, de forma que, si el USUARIO reside o tiene su domicilio
        establecido en otro país y decide acceder o utilizar el SITIO WEB lo
        hará bajo su completa responsabilidad y deberá asegurarse de que tal
        acceso, navegación y/o interacción cumple con la legislación local que
        le es aplicable, no asumiendo <b>Club Cimarrones de Sonora FC</b> ninguna
        responsabilidad que se pueda derivar de ello.</p>
        <p class="texto-pol">El uso de nuestros
        servicios así como la compra de nuestros productos implica que usted ha
        leído y aceptado los Términos y Condiciones de Uso contenidos en el
        presente contrato.</p>
        <p class="texto-pol">En algunos casos, será necesario que el usuario
        realice un registro, con ingreso de datos personales fidedignos y
        definición de una contraseña. El usuario puede elegir y cambiar la clave
        para su acceso de administración de la cuenta en cualquier momento. No
        asumimos la responsabilidad en caso de que entregue dicha clave a
        terceros.</p>
        <p class="texto-pol">Todas las compras y transacciones que se lleven a cabo por
        medio del SITIO WEB, están sujetas a un proceso de confirmación y
        verificación, el cual podría incluir la verificación del stock y
        disponibilidad de producto, validación de la forma de pago, validación
        de la factura (en caso de existir) y el cumplimiento de las condiciones
        requeridas por el medio de pago seleccionado. En algunos casos puede que
        se requiera una verificación por medio de correo electrónico.</p>
        <p class="texto-pol">Los precios de los productos ofrecidos en esta TIENDA ONLINE son válidos
        solamente en las compras realizadas en este SITIO WEB.</p>
        <p class="texto-pol"><b>2. DEL USUARIO.</b></p>
        <p class="texto-pol">Es considerado USUARIO y en consecuencia, sujeto al cumplimiento de los
        presentes Términos y Condiciones, cualquier persona que ingrese,
        navegue, acceda y/o adquiera cualquiera de los productos ofertados en el
        SITIO WEB. Es responsabilidad del USUARIO utilizar el SITIO WEB de
        acuerdo a la forma en la que fue diseñado, en este sentido, queda
        prohibida la utilización de cualquier tipo de software que automatice la
        interacción o descarga de contenidos, productos y/o servicios ofertados
        a través del SITIO WEB, además de comprometerse a utilizar la
        información, contenidos, productos y/o servicios ofrecidos de manera
        lícita, sin contravenir lo dispuesto a los presentes TERMINOS Y
        CONDICIONES, la moral y/o el orden público, absteniéndose de realizar
        cualquier acto que pueda suponer una afectación a los derechos de
        terceros o perjudique de algún modo a <b>Club Cimarrones de Sonora FC</b> y/o
        su SITIO WEB.</p>
        <p class="texto-pol">Así mismo, el USUARIO se compromete a proporcionar
        información lícita y veraz en los formularios habilitados en el SITIO
        WEB, en los cuales el usuario tenga que proporcionar ciertos datos o
        información para el acceso a cotizaciones, promociones y/o compra de
        productos en la TIENDA ONLINE, poniendo a su disposición desde este
        momento nuestro Aviso de Privacidad Integral en la siguiente liga:
        <a href="/politica-privacidad">www.cimarronesfc.mx/empresa/privacidad</a>. En todo caso, el USUARIO tiene
        la absoluta responsabilidad de notificar de forma inmediata a <b>Club
        Cimarrones de Sonora FC</b> acerca de cualquier hecho que permita suponer el
        uso indebido de la información registrada en dichos formularios, tales
        como, robo, extravío o acceso no autorizado a cuentas y/o contraseñas,
        con el fin de proceder a su inmediata cancelación, no siendo responsable
        <b>Club Cimarrones de Sonora FC</b> en ningún caso por el uso inadecuado y/o
        negligente que le de el USUARIO a su usuario y contraseña.</p>
        <p class="texto-pol">El solo
        acceso al SITIO WEB no supone la existencia de ningún tipo de relación
        entre <b>Club Cimarrones de Sonora FC</b> y el USUARIO.</p>
        <p class="texto-pol">Al tratarse de un SITIO
        WEB dirigido exclusivamente a personas que cuenten con la mayoría de
        edad, el USUARIO manifiesta bajo protesta de decir verdad ser mayor de
        edad y disponer de la capacidad jurídica para sujetarse a los presentes
        TERMINOS Y CONDICIONES.</p>
        <p class="texto-pol"><b>3. LICENCIA</b></p>
        <p class="texto-pol"><b>Club Cimarrrones de Sonora FC</b>, a
        través de su SITIO WEB, concede una licencia para que el USUARIO utilice
        los productos que son vendidos en este SITIO WEB de acuerdo a los
        Términos y Condiciones que se describen en este contrato.</p>
        <p class="texto-pol"><b>4. USO NO
        AUTORIZADO</b></p>
        <p class="texto-pol">Usted no puede ofrecer ningún tipo de redistribución y/o
        reventa de nuestros productos.</p>
        <p class="texto-pol"><b>5. POLITICA EN MATERIA DE PROPIEDAD
        INTELECTUAL E INDUSTRIAL</b></p>
        <p class="texto-pol">El USUARIO no puede declarar propiedad
        intelectual o exclusiva a ninguno de nuestros productos, modificado o
        sin modificar. Todos los productos son propiedad de <b>Club Cimarrones de
        Sonora FC</b>. En caso de que no se especifique lo contrario, nuestros
        productos se proporcionan sin ningún tipo de garantía, expresa o
        implícita. En ningún modo esta compañía será responsable de ningún daño
        incluyendo, pero no limitado a daños directos, indirectos, especiales,
        fortuitos y/o consecuentes u otras pérdidas resultantes del uso o de la
        imposibilidad de utilizar nuestros productos.</p>
        <p class="texto-pol"><b>Club Cimarrones de Sonora
        FC</b> es titular de todos los derechos de propiedad intelectual e
        industrial del SITIO WEB, entendiendo por este el código de fuente que
        hace posible su funcionamiento, así como las imágenes, archivos de audio
        y/o video, logotipos, marca, combinaciones de colores, estructuras,
        diseños y demás elementos que lo distinguen. Están, por consiguiente,
        protegidas por la legislación mexicana en materia de propiedad
        intelectual e industrial, así como por los tratados internacionales de
        los que México es parte. Por consiguiente, queda expresa y estrictamente
        prohibida la reproducción total o parcial, distribución y/o difusión de
        los contenidos del SITIO WEB con fines comerciales en cualquier soporte
        y/o por cualquier medio sin la autorización previa y por escrito de <b>Club
        Cimarrones de Sonora FC.</b></p>
        <p class="texto-pol">El USUARIO se compromete a respetar los
        derechos de propiedad intelectual e industrial de <b>Club Cimarrones de
        Sonora FC,</b> no obstante, además de poder visualizar los elementos del
        SITIO WEB podrá imprimirlos, copiarlos y/o almacenarlos siempre y cuando
        sea destinado para uso estrictamente privado y personal.</p>
        <p class="texto-pol">Por otro lado,
        el USUARIO se compromete a abstenerse de suprimir, alterar y/o manipular
        cualquier elemento, archivo y/o contenido del SITIO WEB y por ningún
        motivo realizará actos tendientes a vulnerar la seguridad, archivos y/o
        bases de datos que se encuentren protegidos, ya sea a través de un
        acceso restringido mediante usuario y contraseña o por que no cuente con
        los permisos por parte de <b>Club Cimarrones de Sonora FC</b> para
        visualizarlos, editarlos y/o manipularlos.</p>
        <p class="texto-pol">En caso de que el USUARIO o
        algún tercero considere que cualquiera de los contenidos del SITIO WEB
        suponga una violación a los derechos de protección de propiedad
        industrial y/o intelectual, deberá comunicarlo inmediatamente a <b>Club
        Cimarrones de Sonora FC</b> a través de los datos de contacto disponibles en
        el propio SITIO WEB.</p>
        <p class="texto-pol"><b>6. PRODUCTOS, OFERTAS Y STOCK</b></p>
        <p class="texto-pol">El precio de los
        productos en el SITIO WEB se muestra en pesos mexicanos con IVA
        incluido.</p>
        <p class="texto-pol">El precio del producto es el vigente al momento de la compra,
        una vez se acepta el carrito y se procede al pago. Los precios expuestos
        pueden revisarse y modificarse en cualquier momento por <b>Club Cimarrones
        de Sonora FC</b> sin que ello genere derechos a quienes ya han realizado
        compras posteriormente.</p>
        <p class="texto-pol">Las ofertas tienen una duración temporal
        concreta, por eso las ofertas incluidas en la TIENDA ONLINE del SITIO
        WEB serán válidas durante el período que esté fijado o, en su defecto,
        durante todo el tiempo que permanezcan accesibles al USUARIO para su
        compra.</p>
        <p class="texto-pol">Los productos que están en la TIENDA ONLINE del SITIO WEB son
        productos en stock y por lo tanto están disponibles en los almacenes de
        LA EMPRESA y listos para el envío en la fecha que señale el USUARIO
        (cliente) en su orden de compra.</p>
        <p class="texto-pol">Si debido a una carencia de stock en el
        almacén por error informático o logístico no se pudiera atender el
        pedido total o parcialmente, LA EMPRESA se pondrá en contacto con el
        comprador a través de la dirección de correo electrónico o teléfono,
        para la confirmación del mismo y su posterior envío en un plazo
        diferente al general establecido en esta política, o bien para proceder
        a la anulación del pedido y devolución del importe. La devolución se
        realizará a través del medio de pago utilizado por el cliente.</p>
        <p class="texto-pol">De forma
        esporádica, en función de la temporada y del stock disponible, alguno de
        los productos pueden variar. Se recomienda al USUARIO que, además de ver
        la foto, compruebe que la descripción del producto que quiere comprar
        coincide con lo que desea.</p>
        <p class="texto-pol"><b>7. SISTEMA DE COMPRA</b></p>
        <p class="texto-pol">Para realizar un pedido
        es necesario entrar a la página <b>www.cimarronesfc.mx</b>. Una vez elegido el
        producto, el USUARIO podrá ir al carrito y comprobar su pedido con los
        gastos asociados que en su caso se generen. Podrá elegir finalizar la
        compra o seguir comprando. Si elige finalizar deberá rellenar los datos
        necesarios para cumplir con la finalidad de entrega del producto.</p>
        <p class="texto-pol">La
        compra otorga legitimación a LA EMPRESA para tratar sus datos con fines
        de gestión de la compra.</p>
        <p class="texto-pol">LA EMPRESA declara y garantiza que cuenta con
        todas las medidas técnicas y organizativas adecuadas conforme a los
        requisitos señalados en las leyes aplicables de protección de datos y
        conforme al presente Contrato, incluyendo, de manera enunciativa mas no
        limitativa, aquellas medidas requeridas para proteger la información
        proporcionada por el USUARIO de la divulgación no autorizada o acceso a
        sus datos y para proteger la seguridad, confidencialidad e integridad de
        la información que el USUARIO suministra a LA EMPRESA.</p>
        <p class="texto-pol"><b>8. FORMA DE PAGO
        Y FACTURACIÓN</b></p>
        <p class="texto-pol">EL CLIENTE puede elegir entre los siguientes medios de
        pago:</p>
        <p class="texto-pol lista-pol">• El Usuario puede realizar el pago de su compra con tarjeta de crédito o débito mediante Openpay.</p>
        
        <p class="texto-pol">Para finalizar el pedido el USUARIO deberá aceptar las condiciones
        de venta y el Aviso de Privacidad Integral, los presentes Términos y
        Condiciones y la política de protección de datos, a cuyos contenidos
        puede el usuario acceder a través de los links a su disposición. No será
        posible realizar una compra sin esta aceptación. Si tiene dudas sobre
        las mismas podrá remitir un email a hola@cimarronesfc.com.mx.</p>
        <p class="texto-pol">Si el
        USUARIO desea una factura de su compra deberá proporcionar el NIF/CIF en
        el formulario del pedido, al tiempo de introducir sus datos personales.
        Así mismo, puede solicitarlo a LA EMPRESA a través de email
        hola@cimarronesfc.com.mx.</p>
        <p class="texto-pol">Al confirmar el pedido, el comprador recibirá
        en el plazo máximo de 24 a 48 horas, en el correo electrónico que haya
        facilitado en los datos de facturación, una confirmación del pedido
        realizado.</p>
        <p class="texto-pol"><b>9. ENVÍOS Y DEVOLUCIONES</b></p>
        <p class="texto-pol">La entrega de los pedidos se
        realizará a través de una agencia de paquetería ajena a LA EMPRESA.</p>
        <p class="texto-pol">Los
        pedidos se entregarán en el domicilio indicado por EL CLIENTE, por lo
        que LA EMPRESA no asume ninguna responsabilidad cuando la entrega no
        pueda efectuarse como consecuencia de que los datos facilitados sean
        inexactos o incompletos o cuando la entrega no pueda efectuarse por
        ausencia del destinatario. Sin perjuicio de lo anterior, LA EMPRESA
        adoptará las medidas exigibles a un diligente comerciante para que la
        entrega pueda efectuarse lo antes posible, tanto para satisfacción del
        remitente como del destinatario.</p>
        <p class="texto-pol">Los plazos de entrega indicados son
        aproximados y pueden sufrir alteraciones por causas o circunstancias
        extraordinarias (fechas señaladas como las navideñas, huelgas de
        transporte, acontecimientos extraordinarios, entre otros), que en ningún
        caso son responsabilidad de LA EMPRESA.</p>
        <p class="texto-pol">LA EMPRESA no puede garantizar
        la entrega de los pedidos en hospitales, organismos públicos u otros
        inmuebles en los que existan restricciones para el acceso al público en
        general, quedando cumplido el encargo y liberado de responsabilidad,
        cuando el pedido se haya puesto a disposición del destinatario en la
        recepción o lugar que haga las veces de control de acceso.</p>
        <p class="texto-pol">Si el
        comprador todavía no ha recibido el envío en el tiempo previamente
        pactado, bastará con que remita la declaración al correo
        hola@cimarronesfc.com.mx, procediendo LA EMPRESA a la devolución del
        importe abonado por el comprador (incluidos los gastos de envío si se
        trata del envío completo).</p>
        <p class="texto-pol">En caso de que el comprador reciba el pedido
        dañado o en malas condiciones o haya habido un error en el envío, tiene
        derecho a la devolución debiendo notificarlo a LA EMPRESA en las
        primeras 24 horas tras su recepción mandando un correo electrónico a
        hola@cimarronesfc.com.mx en el que deberá poner en el asunto “devolución
        de pedido dañado”, “devolución de pedido defectuoso” o “error en el
        envío”.</p>
        <p class="texto-pol">LA EMPRESA se pondrá en contacto con el comprador para indicarle
        los pasos a seguir para hacer el cambio. Los gastos de envío de la
        devolución correrán por cuenta del comprador. Si se comprobara que
        efectivamente estaba dañado el pedido, era defectuoso o efectivamente
        hubo un error en el envío, se procederá a la sustitución del producto
        siempre que este se haya realizado de acuerdo con las instrucciones de
        devolución dadas por LA EMPRESA.</p>
        <p class="texto-pol">LA EMPRESA dispone de un control
        exhaustivo de envíos, por lo que antes de cualquier devolución se
        procederá a la verificación de los stocks, pesos y otras medidas de
        seguridad que se tienen instaladas para asegurar que los envíos sean
        correctos. Los gastos de envío de la devolución correrán por cuenta del
        comprador.</p>
        <p class="texto-pol"><b>10. GARANTÍA DE COMPRA</b></p>
        <p class="texto-pol">LA EMPRESA solo vende a través del
        SITIO WEB productos originales de calidad, avalados por firmas
        comerciales.</p>
        <p class="texto-pol"><b>11. COMPROBACIÓN ANTIFRAUDE</b></p>
        <p class="texto-pol">La compra del cliente puede ser
        aplazada para la comprobación antifraude. También puede ser suspendida
        por más tiempo para una investigación más rigurosa, para evitar
        transacciones fraudulentas sin que ello implique responsabilidad para LA
        EMPRESA</p>
        <p class="texto-pol"><b>12. PRIVACIDAD</b></p>
        <p class="texto-pol">La información personal que usted envía cuenta
        con la seguridad necesaria. Los datos ingresados por el usuario o en el
        caso de requerir una validación de los pedidos no serán entregados a
        terceros, salvo que deba ser revelada en cumplimiento a una orden
        judicial o requerimientos legales.</p>
        <p class="texto-pol">La suscripción a boletines de correos
        electrónicos publicitarios es voluntaria y podría ser seleccionada al
        momento de crear su cuenta.</p>
        <p class="texto-pol"><b>13. LEGISLACION Y JURISDICCION APLICABLE.</b></p>
        <p class="texto-pol"><b>Club Cimarrones de Sonora FC</b> se reserva el derecho de presentar las
        acciones que considere necesarias por la utilización indebida del SITIO
        WEB, su contenido y/o servicios y/o por el incumplimiento de los
        presentes TERMINOS Y CONDICIONES.</p>
        <p class="texto-pol">La relación entre el USUARIO y <b>Club
        Cimarrones de Sonora FC,</b> en caso de controversia acuerdan se regirá
        única y exclusivamente por la legislación mexicana vigente, sometiéndose
        a la jurisdicción ordinaria de los tribunales de la ciudad de
        Hermosillo, Sonora, México, renunciando a cualquier otro fuero que por
        razón de territorio, domicilios presentes o futuros, les pudiera
        corresponder.
      </p>
    </div>

    <div id="footerSection">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../components/footer";
import HeaderHome from "../components/HeaderHome";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {};
  },
  components: {
    HeaderHome,
    Footer,
  },
  async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },
  computed: {
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
  },
};
</script>

<style scoped>
.politica-cont {
  padding: 10.625vw 21.042vw;
}
p.texto-pol {
  font-size: 1.042vw;
  line-height: 179.5%;
  text-align: justify;
  color: #a0a0a0;
  margin: 2.2552vw 0;
}
.titulo-pol {
  font-size: 2.344vw;
  line-height: 2.708vw;
  color: #b70d1d;
  font-family: "Rajdhani";
  margin-bottom: 3vw;
}
.titulo-pol span {
  color: #01203a;
  font-family: "Rajdhani";
}
p.texto-pol.lista-pol {
  margin: 0;
}

@media (max-width: 768px) {
  .politica-cont {
    padding: 33.625vw 11.56vw 10.625vw;
  }
  .titulo-pol {
    font-size: 4.831vw;
    line-height: 4.831vw;
    margin-bottom: 12.077vw;
  }
  p.texto-pol {
    font-size: 2.899vw;
    line-height: 179.5%;
    margin: 4.2552vw 0;
  }
}
</style>